import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw from 'twin.macro';
import {
  BlueButton,
  CallOut,
  CallOutCarousel,
  CallOutList,
  ContentSection,
  DownloadButton,
  Footnotes,
  H2,
  Hero,
  InternalPaddingNarrow,
  Layout,
  NERLYNX,
  NoBreak,
  References,
  SEO,
  VoucherFooter,
} from '~/components';
import { Frontmatter } from '~/config';
import { resourcesData } from '~/config/resources-content';
import heroBackground from '~/images/hero-ipad.jpg';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Resources',
  order: 7,
  screenShoot: true,
  anchors: [
    {
      id: 'resources-for-your-practice',
      label: 'RESOURCES FOR YOUR PRACTICE',
      ariaLabel: 'Resources for your practice',
    },
    {
      id: 'puma-patient-lynx-patient-support',
      label: 'PUMA PATIENT LYNX PATIENT SUPPORT',
      ariaLabel: 'Puma patient Lynx patient support',
    },
    {
      id: 'resources-for-your-patients',
      label: 'RESOURCES FOR YOUR PATIENTS',
      ariaLabel: 'Resources for your patients',
    },
  ],
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout anchors={frontmatter.anchors}>
      <SEO
        title="Resources | NERLYNX® (neratinib) tablets"
        description="Explore resources about NERLYNX to utilize in your practice and with your HER2+ breast cancer patients."
        path={props.location?.pathname}
      />
      <Hero
        backgroundImage={heroBackground}
        headlinePadding="right"
        characterWidth={70}
        backgroundStyle={tw`background-position[63% 8%] background-size[auto 140%] md:(bg-cover)`}
        titleStyle={tw`mb-32 -mt-4 md:(my-0)`}
      >
        Resources to utilize in your practice and with{' '}
        <NoBreak>your patients</NoBreak>
      </Hero>
      <section id="resources-for-your-practice">
        <ContentSection flavor="light" tw="md:block">
          <H2 cyan tw="md:(mb-12)">
            Downloadable materials
          </H2>
          <CallOutCarousel
            data={resourcesData.topSection}
            customCss={tw`md:flex`}
          />
          <Footnotes
            footnotes={['completion']}
            references="1"
            abreviations={['eBC', 'HR-', 'mBC']}
            tw="mb-8 mt-10"
          />
        </ContentSection>
      </section>
      <section id="puma-patient-lynx-patient-support">
        <ContentSection flavor="lightBlue" customCss={tw`py-16`}>
          <div tw="mb-16">
            <H2 cyan>
              Puma Patient Lynx offers a variety of support and financial
              assistance programs for your patients
            </H2>
          </div>
          <div tw="md:(mb-16)">
            <CallOutList data={resourcesData.pumaSection} />
          </div>
        </ContentSection>
      </section>
      <section id="resources-copay-assistance">
        <VoucherFooter />
      </section>
      <section id="resources-for-your-patients">
        <ContentSection flavor="dark" customCss={tw`md:(py-12)`}>
          <div tw="mb-12">
            <H2 cyan>Resources for your patients</H2>
          </div>
          <CallOut
            tw="mb-4"
            barColor="cyan"
            bgColor="pastelDarkBlue"
            description={
              <p tw="mt-8 mb-4">
                Share important resources, such as the{' '}
                <strong>Mentor Program</strong>, with your patients to provide
                them support throughout their treatment.
              </p>
            }
          >
            <BlueButton
              customCss={tw`sm:px-16`}
              size="cta"
              to="https://nerlynx.com/her2-patient-support/#advocate-resources"
            >
              See what's available
            </BlueButton>
          </CallOut>
        </ContentSection>
      </section>
      <section>
        <ContentSection
          flavor="lightBlue"
          customCss={tw`pt-8 pb-16 md:(pt-20)`}
        >
          <div tw="mb-8 md:(mb-16)">
            <H2 cyan>
              Download tools to facilitate your discussions with patients
              receiving <NERLYNX />
            </H2>
          </div>
          <CallOut
            {...resourcesData.bottomSection[0]}
            customCss={tw`w-full px-0`}
          >
            <DownloadButton
              {...resourcesData.bottomSection[0].buttons[0]}
              customCss={tw`sm:px-12`}
            >
              Download
            </DownloadButton>
          </CallOut>
        </ContentSection>
        <div tw="[background-color: #E7EEF2] pb-8">
          <InternalPaddingNarrow>
            <References references={['moy b']} />
          </InternalPaddingNarrow>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
